<template>
    <div class="card-tail">
        <div>
            <el-form v-loading="$waiting.is('loading')">
                <div class="p-5">
                    <header class="text-header-blue text-xl font-semibold mb-8">Förvaltningsberättelse</header>
                    <div v-if="annualReportData">
                        <!-- Årsredovisningen i SEK -->
                        <AnnualReportSek
                            @create="createInfo()"
                            @close="closeCard"
                            title="Årsredovisningen i SEK"
                            :toggle-value="annualReportData.addAnnualReportIsSek"
                            @toggle-change="handleToggleChange"
                            :input-text="annualReportData.annualReportSek"
                            @update:text="annualReportData.annualReportSek = $event"
                        />
                        <!-- Allmänt om verksamheten -->
                        <GeneralBusinessInfo
                            @create="createInfo()"
                            @close="closeCard"
                            title="Allmänt om verksamheten"
                            :toggle-value="annualReportData.addGeneralBusinessInfo"
                            @toggle-change="handleToggleChange"
                            :input-text="annualReportData.generalBusinessInfo"
                            @update:text="annualReportData.generalBusinessInfo = $event"
                            :annual-report-id="annualReportId"
                        />
                        <!-- Säte -->
                        <ResidenceInfo
                            @create="createInfo()"
                            @close="closeCard"
                            title="Säte"
                            :toggle-value="annualReportData.addResidenceInfo"
                            @toggle-change="handleToggleChange"
                            :input-text="annualReportData.residenceInfo"
                            @update:text="annualReportData.residenceInfo = $event"
                            :annual-report-id="annualReportId"
                        />
                        <!-- Väsentliga händelser under räkenskapsåret -->
                        <FinancialYearEvent
                            @create="createInfo()"
                            @close="closeCard"
                            title="Väsentliga händelser under räkenskapsåret"
                            :toggle-value="annualReportData.addFinancialYearEventTitle"
                            @toggle-change="handleToggleChange"
                            :input-text="annualReportData.financialYearEventTitle"
                            @update:text="annualReportData.financialYearEventTitle = $event"
                        />
                        <!-- Flerårsöversikt -->
                        <MultiYearModal
                            @create="createInfo()"
                            @close="closeCard"
                            title="Flerårsöversikt"
                            :toggle-value="annualReportData.addYearlyOverview"
                            @toggle-change="handleToggleChange"
                            :input-text="annualReportData.yearlyOverviewComment"
                            @update:text="annualReportData.yearlyOverviewComment = $event"
                            :yearly-overview-data="annualReportData.yearlyOverview"
                            @update-yearly-overview-data="updatedYearlyOverviewData = $event"
                            :show-comment="annualReportData.showYearlyOverviewComment"
                            @toggle-comment="annualReportData.showYearlyOverviewComment = $event"
                            @update:showYearlyOverviewComment="annualReportData.showYearlyOverviewComment = $event"
                        />
                        <!-- Förändringar i eget kapital -->
                        <div v-if="annualReportData.changesInEquity">
                            <ChangeInEquityModal
                                @create="createInfo()"
                                @close="closeCard"
                                title="Förändringar i eget kapital"
                                :toggle-value="annualReportData.addChangesInEquity"
                                @toggle-change="handleToggleChange"
                                :input-text="annualReportData.changesInEquityComment"
                                @update:text="annualReportData.changesInEquityComment = $event"
                                :title-data="annualReportData.changesInEquityTitle"
                                :change-in-equity-data="annualReportData.changesInEquity.changesInEquityDetails"
                                @update-change-in-equity-data="updateChangeInEquityData = $event"
                                :show-comment="annualReportData.showChangesInEquityComment"
                                @toggle-comment="annualReportData.showChangesInEquityComment = $event"
                                @update:showChangesInEquityComment="annualReportData.showChangesInEquityComment = $event"
                            />
                        </div>
                        <!-- Resultatdisposition -->
                        <div v-if="annualReportData.incomeStatementAndProfitDistributions">
                            <ProfitDispositionModal
                                @create="createInfo()"
                                @close="closeCard"
                                title="Resultatdisposition"
                                :toggle-value="annualReportData.addIncomeStatementAndProfitDistribution"
                                @toggle-change="handleToggleChange"
                                :table-data="annualReportData.incomeStatementAndProfitDistributions"
                                @update-table-data="updateTableData = $event"
                                :input-text="annualReportData.incomeStatementAndProfitDistributions.statementFromBoard"
                                @update:text="annualReportData.incomeStatementAndProfitDistributions.statementFromBoard = $event"
                                :show-comment="annualReportData.showCompletion"
                                @toggle-comment="annualReportData.showCompletion = $event"
                                @update:showCompletion="annualReportData.showCompletion = $event"
                            />
                        </div>

                        <!-- Modal för utkast -->
                        <ManagementReportPdfCard
                            ref="inspectManagementReportModal"
                            :client-info="clientInfo"
                            :visible="draftVisible"
                            @close="draftVisible = false"
                            :annual-report-data="annualReportData"
                        />
                    </div>
                </div>

                <div class="p-5 flex justify-end w-full">
                    <el-button size="medium" type="primary" @click="goToPageBefore">Tillbaka</el-button>
                    <el-button size="medium" type="warning">Utkast Årsredovisning</el-button>
                    <el-button size="medium" class="button is-info" @click="showDraft">Granska</el-button>
                    <el-button size="medium" type="primary" @click="goToNextPage">Nästa</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import Api from "../annualReport.api";
export default {
    props: {
        clientInfo: {
            type: Object,
        },
    },
    components: {
        AnnualReportSek: () => import("./AnnualReportSek.vue"),
        GeneralBusinessInfo: () => import("./GeneralBusinessInfo.vue"),
        ResidenceInfo: () => import("./ResidenceInfo.vue"),
        FinancialYearEvent: () => import("./FinancialYearEventInfo.vue"),
        MultiYearModal: () => import("./MultiYearOverview.vue"),
        ChangeInEquityModal: () => import("./ChangeInEquity.vue"),
        ProfitDispositionModal: () => import("./ProfitDisposition.vue"),
        ManagementReportPdfCard: () => import("./ManagementReportDraft.vue"),
    },
    data() {
        return {
            annualReportId: undefined,
            draftVisible: false,
            annualReportData: {},
            oldInputText: "",
        };
    },
    async created() {
        this.$waiting.start("loading");
        this.annualReportId = this.$route.params.annualReportId;
        this.annualReportData = await Api.getManagementReportDetails(this.annualReportId);
        if (this.annualReportData == "" || this.annualReportData == null) {
            this.annualReportData = await Api.createManagementReport(this.annualReportId);
            this.annualReportData = await Api.getManagementReportDetails(this.annualReportId);
        }
        this.oldInputText = this.annualReportData.generalBusinessInfo;
        this.$waiting.end("loading");
    },
    methods: {
        async goToNextPage() {
            await this.$router.push(`/annualReportSubmenu/incomeStatement/${this.annualReportId}`);
        },
        async goToPageBefore() {
            await this.$router.push(`/annualReportSubmenu/coverSheet/${this.annualReportId}`);
        },
        showDraft() {
            this.draftVisible = true;
            this.$nextTick(() => {
                this.$refs.inspectManagementReportModal.$el.focus();
            });
        },
        async handleToggleChange(eventData) {
            if (eventData.source === 1) {
                this.annualReportData.addAnnualReportIsSek = eventData.value;
            }
            if (eventData.source === 2) {
                this.annualReportData.addGeneralBusinessInfo = eventData.value;
            }
            if (eventData.source === 3) {
                this.annualReportData.addResidenceInfo = eventData.value;
            }
            if (eventData.source === 4) {
                this.annualReportData.addFinancialYearEventTitle = eventData.value;
            }
            if (eventData.source === 5) {
                this.annualReportData.addYearlyOverview = eventData.value;
            }
            if (eventData.source === 6) {
                this.annualReportData.addChangesInEquity = eventData.value;
            }
            if (eventData.source === 7) {
                this.annualReportData.addIncomeStatementAndProfitDistribution = eventData.value;
            }
            await Api.updateManagementReportDetails(this.annualReportId, this.annualReportData);
        },
        async createInfo() {
            await Api.updateManagementReportDetails(this.annualReportId, this.annualReportData);
        },
        async closeCard(value) {
            if (value.inputTextNewValue) {
                this.$waiting.start(value.load);
                this.annualReportData = await Api.getManagementReportDetails(this.annualReportId);
                this.$waiting.end(value.load);
            } else {
                this.annualReportData = await Api.getManagementReportDetails(this.annualReportId);
            }
        },
    },
};
</script>

<style>
.custom-textarea .el-textarea__inner:disabled {
    color: #444a55;
    border-color: #aaaaaa;
    background-color: #eeeeee;
    font-size: 14px;
}
.custom-textarea {
    font-size: 14px;
}
.round-button,
.round-button:active,
.round-button:focus {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #415770;
    font-size: x-large;
}
.round-button:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #6e85a0;
    font-size: x-large;
}
.save-button {
    background-color: #48c78e;
    border-color: #48c78e;
    color: #ffffff;
}
.save-button:hover {
    background-color: #5bd6a3;
    color: #ffffff;
}
.tooltip-content {
    width: 500px;
    padding: 5px;
    border: none;
}
.management-report-card-box {
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 30px;
    width: fit-content;
    min-width: 800px;
}
</style>
